<template>
  <form @submit.prevent="submit" id="form-field">
    <page :data="pageData" :is-child="_isChild">
      <template #cmslefttype(form)="{ data: d }">
          <all-field :data="d.Data" @error="setErrors"></all-field>
          <p class="my-4 font-bold">
            {{config.Note}}
          </p>
          <div class="flex w-full justify-end">
            <b-button class="w-full" :type="hasError ? 'is-light' : 'is-primary'" :native-type="hasError ? undefined : submit">{{d.SubmitButton}}</b-button>
          </div>
          <b-modal
            :active="result"
            has-modal-card
          >
            <div class="relative">
              <div @click="result = null" class="absolute bg-red-400 cursor-pointer" style="border-radius: 20px; top: -10px; right: 4px; z-index: 100 !important;">
                  <b-icon icon="close" class="rounded-full p-4 my-auto" custom-size="mdi-24px" type="is-white"></b-icon>
              </div>
            </div>
            <div class="modal-card w-1/2">
              <section class="modal-card-body mx-4 rounded-md">
                <list-item v-for="(v, k) in result" :key="k" :data="{Text: k, BorderWidth: 0, Class: { 'font-bold': k === 'Total' }}" class="pt-1">
                  <template #righttext>{{ v | apiPath == '/livetracking' ? user.CurrencyObj : currency(user.CurrencyObj) }}</template>
                </list-item>
              </section>
            </div>
          </b-modal>
      </template>
    </page>
  </form>
</template>

<script>
import FormPage from './base/FormPage.vue'
import ListItem from '@/components/vaddress/ListItem'
export default {
  extends: FormPage,
  components: {
    ListItem
  },
  data () {
    return {
      result: null,
      window: window.innerWidth < 767
    }
  },
  methods: {
    async submit () {
      this.$store.commit('service/SET_CONDITION_NOTIF', true)
      const loading = this.$buefy.loading.open()
      try {
        const data = this.getData('form-field')
        this.result = await this.$baseApi.post(this.apiPath, data)
        if (this.isSm) {
          this.$nextTick(() => {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
          })
        }
      } catch (err) {
        this.handleSubmitError(err)
      } finally {
        loading.close()
      }
    }
  }
}
</script>

<style>

</style>
